export class BatchUpdateController {

    constructor(options) {
        this.dataObject = options.dataObject;
        this.column = options.column;
        this.batchFields = options.batchFields;
        this.selectedItems = options.selectedRows;
    }

    doBatchUpdate(batchItem) {
        //==========================================
        // TODO: PROPER BATCH UPDATE LOGIC GOES HERE
        //==========================================
        
        // TODO: Support callbacks

        // debug code bellow
        this.selectedItems.forEach(item => {
            this.batchFields.forEach(field => {
                if (batchItem[field].constructor.name === 'FormulaObject') {
                    batchItem[field].replaceFieldsWithValues(item);
                    const newValue = batchItem[field].operation.applyFunction(batchItem[field].value);
                    item[field] = newValue;
                } else {
                    item[field] = batchItem[field];
                }
            });
        });
    }
}